import clsx from 'clsx'
import React, { Component } from 'react'
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import { enableChat } from '../../../utility/chat'

function Reason({ imageSource, title, subText }) {
  return (
    <div className="reason">
      <img src={imageSource} />
      <h3 className="text-xl">{title}</h3>
      <p>{subText}</p>
    </div>
  )
}
//
const homePageLoadEventArguments = {
  dataLayer: {
    event: 'homeHACPageLoad',
  },
  dataLayerName: 'CYMDataLayer',
}
const tagManagerArguments = {
  gtmId: process.env.REACT_APP_GTM_ID,
  dataLayerName: 'CYMDataLayer',
}

const waysColOne = [
  {
    bold: 'LEADERSHIP',
    text: ': Identify and prioritize 3 initiatives that will make your business more money. ',
    icon: 'leadership.svg',
    alt: 'Leadership',
  },
  {
    bold: 'SALES',
    text: ': Create a Sales conversation that will help you and your team confidently close more deals without being salesy.',
    icon: 'sales.svg',
    alt: 'Sales',
  },
  {
    bold: 'OPERATIONS',
    text: ': Install a management and productivity system that will keep your operation costs low and your productivity levels high.',
    icon: 'operations.svg',
    alt: 'Operations',
  },
  // {
  //   text: 'Clarify your message so customers listen',
  //   icon: 'clarify.svg',
  //   alt: 'Light Bulb',
  // },
  // {
  //   text: 'Help you run an effective execution plan',
  //   icon: 'help-you-run.svg',
  //   alt: 'Plan in action',
  // },
]

const waysColTwo = [
  {
    bold: 'MARKETING',
    text: ': Create a marketing funnel to generate consistent business and predictable cash flow.',
    icon: 'marketing.svg',
    alt: 'Marketing',
  },
  {
    bold: 'PRODUCTS',
    text: ': Perform profitability audits and install product briefs to ensure your product offering is highly lucrative.',
    icon: 'products.svg',
    alt: 'Product',
  },
  {
    bold: 'CASH FLOW',
    text: ': Learn to use 5 checking accounts so you can manage your finances with zero confusion or stress.',
    icon: 'cashflow.svg',
    alt: 'Funnel',
  },
  // {
  //   text: 'Become a value-driven professional',
  //   icon: 'value-driven.svg',
  //   alt: 'Valuable Person',
  // },
  // {
  //   text: 'Implement a sales framework that positions the customer as the hero',
  //   icon: 'handshake.svg',
  //   alt: 'Hand shake',
  // },
]

const CertificationCard = ({ children }) => (
  <div
    className="certification-card"
    style={{
      borderRadius: '24px',
      background: '#F8F7F4',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: '36px',
    }}
  >
    {children}
  </div>
)

const CertificationTitle = ({ htmlTitle }) => (
  <h2
    dangerouslySetInnerHTML={{ __html: htmlTitle }}
    style={{
      color: '#000',
      fontFamily: 'Montserrat',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 800,
      lineHeight: 'normal',
      marginTop: '36px',
      marginBottom: '16px',
      textAlign: 'left',
    }}
  />
)

const CertificationCopy = ({ children }) => (
  <p
    style={{
      color: '#000',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '24px',
    }}
  >
    {children}
  </p>
)

//dark bg bg-[#181C21]
const SectionContainer = ({ isDark, children }) => (
  <section
    className={clsx(
      'pb-12 flex flex-col space-y-12 justify-center items-center pt-6 min-h-screen md:px-8 px-4',
      isDark ? 'bg-[#181C21]' : 'bg-white'
    )}
    style={{
      backgroundColor: isDark ? '#181C21' : '#fff',
    }}
  >
    {children}
  </section>
)

const GridCard = ({ copy }) => (
  <div className="border border-white-card bg-white shadow-md rounded-3xl flex items-center px-6 py-12 space-x-6">
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6">
        <path
          d="M9.2646 15.8582C9.48449 16.1793 9.95398 16.1803 10.1689 15.8551C10.1709 15.8521 10.1729 15.849 10.1749 15.846C14.2854 9.67579 17.5857 6.28166 21.0791 3.66667C22.5658 2.55522 24.0119 2 25.4154 2C27.2706 2 28.3819 2.05978 28.7494 2.17933C28.9158 2.21378 29 2.40122 29 2.74367C29 3 28.8326 3.33333 28.4988 3.74367C19.4933 14.2969 14.9995 20.5532 12.5253 23.8561C12.2083 24.2796 11.8438 24.6646 11.4268 24.9848C10.6077 25.613 9.61028 26 7.94429 26C7.93636 26 7.92943 26 7.9215 26C6.57841 25.9929 5.3611 25.1895 4.75394 23.9635C4.50433 23.458 4.21511 22.8095 3.8833 21.9747C2.82942 19.307 1 15.538 1 13.538C1 12.8034 1.50911 12.1966 2.52931 11.7173C2.91163 11.54 3.26821 11.386 3.59903 11.2553C4.75988 10.7974 6.07623 11.2107 6.79136 12.2523L9.2646 15.8571V15.8582Z"
          fill="#68E19F"
        />
      </g>
    </svg>
    <p>{copy}</p>
  </div>
)
class HomeHAC extends Component {
  constructor(props) {
    super(props)

    if (process.env.REACT_APP_GA_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_ID)
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: document.title })
    }

    TagManager.initialize(tagManagerArguments)
    TagManager.dataLayer(homePageLoadEventArguments)
    enableChat()
  }

  onSkillClick = selectedSkill => {
    const skillId = this.props.filters.allSkills.filter(skill => selectedSkill === skill.name)
    if (skillId.length > 0) {
      this.props.onFilterChange('skills', [skillId[0].id.toString()])
    }
    this.handleSearch()
  }

  handleSearch = () => this.props.router.push('/search')

  componentDidMount() {
    this.props.onFilterChange('skills', [])
    this.props.onFilterChange('budget', '')
    this.props.onFilterChange('query', '')
    this.props.onFilterChange('lat', '')
    this.props.onFilterChange('lng', '')
    this.props.onFilterChange('locationDisplayName', '')
  }

  render() {
    const isIE = /*@cc_on!@*/ false || !!document.documentMode

    const calculateSearchLink = () => {
      const { router } = this.props
      try {
        if (router.location.pathname.indexOf('podcast') > -1) {
          return '/search/coach-consult'
        }
      } catch {
        //
      }
      return '/search'
    }

    return (
      <div className="Home hac">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Business Coaching for Busy Entrepreneurs | Hire a Coach</title>
          <description>
            Get the coaching you need to grow your business, increase profits, and work less. Find a certified coach
            today!
          </description>
        </Helmet>
        <section className="hero">
          <div className="hero-wrapper">
            <div className="hero-image">
              <div className="white-polygon clip-paths" data-ie={isIE ? 'ie' : ''}>
                <div className="hero-content-triangle"></div>
              </div>
              <div className="white-triangle clip-paths">
                <div className="gold-triangle" data-ie={isIE ? 'ie' : ''}></div>
              </div>
              <div className="hero-content pw">
                <Link to="/">
                  <img
                    style={{
                      height: '80px',
                      width: '65%',
                      marginBottom: '3rem',
                    }}
                    src={require(`../../../assets/hac-new-logo.svg`).default}
                    alt="Hire a Coach"
                  />
                </Link>
                <h1 className="text-black">
                  Business Coaching for <span>Busy Entrepreneurs</span>
                </h1>
                <h5 className="tagline">
                  Work less, make more, and spend more time doing the things you truly love – in your business and in
                  your life.
                </h5>
                <Link role="button" className="largeBtn cta md:mb-6 mb-2" to={calculateSearchLink()}>
                  Find Your Coach
                </Link>
                <Link
                  role="button"
                  className="Button--underline Button--underline__white Button--underline__small cta"
                  to={{
                    pathname: '/search',
                    state: { focusName: true },
                  }}
                >
                  Search By Name
                </Link>
              </div>
            </div>
            <div className="hero-content-bottom">
              <p>
                Most business owners are living with constant stress and overwhelm. That’s not sustainable. Our business
                coaches help you work smarter and faster so your business becomes your vehicle for freedom – not a
                burden.
              </p>
            </div>
            <div className="reasons" data-ie={isIE ? 'ie' : ''}>
              <Reason
                imageSource={require('../../../assets/1-find.png').default}
                title={'1. Find your coach'}
                subText="Find the right coach for your unique needs and industry"
              />
              <Reason
                imageSource={require('../../../assets/2-hire.png').default}
                title={'2. Hire your coach'}
                subText="Get mentored through our proven frameworks"
              />
              <Reason
                imageSource={require('../../../assets/3-revenue.png').default}
                title={'3. Grow your revenue & free time'}
                subText="Enjoy a more profitable business and more time to do what you love"
              />
            </div>
          </div>
        </section>
        <SectionContainer>
          <div>
            <h2 className="md:text-7xl text-5xl font-extrabold text-center tracking-wide">
              <span className="light-text-gradient">Your Coach Will Help You Grow</span>
              <br /> <span className="text-cbPink">a Thriving Business</span>
            </h2>
            <h3 className="text-black text-center text-xl" style={{ fontWeight: 400 }}>
              Whether you’re looking to drive more sales, improve customer experience, <br /> or achieve bigger goals,
              our coaches can help.
            </h3>
          </div>
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 w-10/12">
            <CertificationCard>
              <svg
                style={{
                  minHeight: '120px',
                  minWidth: '120px',
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="124"
                height="124"
                viewBox="0 0 124 124"
                fill="none"
              >
                <path
                  d="M49.4599 76.0325C49.2597 76.1612 49.0989 76.3292 48.9595 76.5115V58.2174L51.8744 56.9163V74.4829L49.4617 76.0325H49.4599Z"
                  fill="#010101"
                />
                <path
                  d="M38.5542 116.644L35.6392 115.116V64.1134L35.9842 64.0098L38.5542 62.8624V116.644Z"
                  fill="#010101"
                />
                <path
                  d="M72.1273 56.9163L75.0423 58.2174V76.5115C74.8993 76.3274 74.7385 76.1594 74.5365 76.0307L72.1273 74.4847V56.9163Z"
                  fill="#010101"
                />
                <path
                  d="M74.6741 82.2056L75.0423 81.832V120.418L72.1273 121.058V82.3039L72.8315 82.5326C73.4606 82.7274 74.1701 82.5952 74.6741 82.2056Z"
                  fill="#010101"
                />
                <path
                  d="M48.9577 81.8356L49.3169 82.1984C49.8388 82.6023 50.5269 82.7382 51.1917 82.5255L51.8709 82.3056V121.06L48.9577 120.42V81.8356Z"
                  fill="#010101"
                />
                <path
                  d="M61.9991 83.2064C62.5496 83.2064 63.0268 83.0313 63.4557 82.7096V122H60.5407V82.7096C60.9697 83.0313 61.4486 83.2064 61.9973 83.2064H61.9991Z"
                  fill="#010101"
                />
                <path
                  d="M85.5834 62.9249L88.0176 64.0116C88.1713 64.0795 88.3339 64.1259 88.4984 64.1545V115.039L85.5834 116.576V62.9249Z"
                  fill="#010101"
                />
                <path
                  d="M73.3605 80.8186L64.1849 77.8375C63.5897 79.5211 62.7533 81.4102 61.9991 81.4102C61.2449 81.4102 60.4085 79.5211 59.8133 77.8375L50.6377 80.8169L50.5626 80.8294L50.4143 80.7775L50.3124 80.5666V77.7625L50.4321 77.5391L58.346 72.4598C57.8992 70.3169 57.388 67.141 57.388 63.8382V52.4875L35.2532 62.3726L35.1549 62.3941L35.0173 62.3512L34.9047 62.1331V58.0761L35.0048 57.8652L47.8013 47.9086C47.6137 46.6111 47.6137 45.1634 47.6173 43.8784V43.4423C47.6173 42.4522 48.3482 41.2958 50.4053 41.2958C52.4624 41.2958 53.1934 42.4522 53.1934 43.4423V43.7122L57.388 40.4487V34.6938C57.388 31.786 57.5918 28.4885 57.8938 26.4886C58.237 24.2242 59.8884 20.8642 61.983 20.7069H62.0188C64.1116 20.8642 65.7648 24.226 66.1079 26.4886C66.41 28.4903 66.6137 31.7878 66.6137 34.6938V40.4487L70.8084 43.7122V43.4423C70.8084 42.4522 71.5393 41.294 73.5964 41.294C75.6535 41.294 76.3845 42.4504 76.3845 43.4423V43.8766C76.3881 45.1616 76.3899 46.6111 76.2004 47.9086L88.997 57.8652L89.0989 58.0761V62.1331L88.9863 62.3512L88.8487 62.3941L88.7504 62.3726L66.6155 52.4875V63.8382C66.6155 67.1428 66.1044 70.3187 65.6576 72.4598L73.5696 77.5391L73.6894 77.7625V80.5666L73.5875 80.7775L73.4392 80.8294L73.3641 80.8169L73.3605 80.8186Z"
                  fill="#010101"
                />
                <path
                  d="M122 61.9991C122 95.1361 95.1379 121.998 62.0009 121.998C28.8639 121.998 2 95.1361 2 61.9991C2 28.8621 28.8639 2 62.0009 2C95.1379 2 122 28.8621 122 61.9991Z"
                  stroke="#010101"
                  stroke-width="3"
                  stroke-miterlimit="10"
                />
              </svg>
              <CertificationTitle htmlTitle={'Small Business <br />Flight Plan'} />
              <CertificationCopy>
                The Flight Plan is Business Made Simple’s 6-step operating system for any small business. Your BMS Coach
                certified in Flight Plan will guide through each step to improve the 6 key areas of your business so you
                can increase revenue and maximize profit.
              </CertificationCopy>
              <div className="flex justify-start items-start mt-auto">
                <Link
                  to={{
                    pathname: 'search',
                    state: {
                      preSelectedFilters: {
                        type: 'certifications',
                        name: 'Flight Plan Coach',
                      },
                    },
                  }}
                  role="button"
                  className="largeBtn cta"
                  style={{
                    marginTop: 'auto',
                    display: 'block',
                    marginLeft: 'none !important',
                    marginRight: 'none !important',
                  }}
                >
                  Find Your Coach
                </Link>
              </div>
            </CertificationCard>
            <CertificationCard>
              <svg
                style={{
                  minHeight: '120px',
                  minWidth: '120px',
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="124"
                height="124"
                viewBox="0 0 124 124"
                fill="none"
              >
                <path
                  d="M122 61.9991C122 95.1361 95.1379 121.998 62.0009 121.998C28.8639 121.998 2 95.1361 2 61.9991C2 28.8621 28.8639 2 62.0009 2C95.1379 2 122 28.8621 122 61.9991Z"
                  stroke="black"
                  stroke-width="3"
                  stroke-miterlimit="10"
                />
                <path
                  d="M30.1471 71.4679V37.1745H40.4665V72.1184C40.4665 77.725 41.2797 80.8133 45.099 80.8133C48.9183 80.8133 49.8119 77.725 49.8119 72.1184V37.1745H60.1314V71.4679C60.1314 81.9518 56.5552 89.1025 45.0972 89.1025C33.6393 89.1025 30.1453 81.9518 30.1453 71.4679H30.1471Z"
                  fill="black"
                />
                <path
                  d="M63.8686 88.3715V37.1745H73.945V58.0583H83.7783V37.1745H93.8547V88.3697H83.7783V66.3475H73.945V88.3697H63.8686V88.3715Z"
                  fill="black"
                />
              </svg>
              <CertificationTitle htmlTitle={'Unreasonable <br />Hospitality'} />
              <CertificationCopy>
                Great brands care about people, not just profit. Will Guidara’s Unreasonable Hospitality Framework helps
                businesses elevate the customer journey and make people feel seen, known, and cared for. Your certified
                coach will walk you through Will Guidara’s Customer Journey Advantage Map, where you’ll learn how to
                elevate meaningful touchpoints that drive long-term loyalty and success.
              </CertificationCopy>
              <div className="flex justify-start items-start mt-auto">
                <Link
                  to={{
                    pathname: 'search',
                    state: {
                      preSelectedFilters: {
                        type: 'certifications',
                        name: 'Unreasonable Hospitality',
                      },
                    },
                  }}
                  role="button"
                  className="largeBtn cta"
                  style={{
                    marginTop: 'auto',
                    display: 'block',
                    marginLeft: 'none !important',
                    marginRight: 'none !important',
                  }}
                >
                  Find Your Coach
                </Link>
              </div>
            </CertificationCard>
            <CertificationCard>
              <svg
                style={{
                  minHeight: '120px',
                  minWidth: '120px',
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="124"
                height="124"
                viewBox="0 0 124 124"
                fill="none"
              >
                <path
                  d="M122.002 62C122.002 95.1375 95.1393 122 62.0018 122C28.8643 122 2 95.1375 2 62C2 28.8625 28.8643 2 62.0018 2C95.1393 2 122.002 28.8625 122.002 62Z"
                  stroke="#010101"
                  stroke-width="3"
                  stroke-miterlimit="10"
                />
                <path
                  d="M62.0947 33.0321C45.9844 33.0321 32.3601 45.7092 32.3601 62.6238C32.3601 79.5384 44.7583 91.7936 61.8284 91.7936C77.9781 91.7936 91.6416 79.3435 91.6416 62.3575C91.6416 45.3714 78.2068 33.0321 62.0965 33.0321H62.0947ZM61.8677 88.253C46.5992 88.253 35.9221 76.4804 35.9221 62.622C35.9221 48.7636 48.1309 36.5691 62.0965 36.5691C77.1756 36.5691 88.1207 48.2971 88.1207 62.3557C88.1207 76.4142 77.138 88.253 61.8695 88.253H61.8677ZM70.4769 44.3776C70.3232 44.3008 70.132 44.3008 69.9408 44.3401C69.8264 44.3776 69.712 44.4545 69.5958 44.5689L57.9232 60.3736C57.9232 60.4504 57.8464 60.5255 57.8089 60.6024L53.1405 79.6063C53.0637 79.9494 53.2174 80.2533 53.523 80.407C53.6767 80.4838 53.868 80.4838 54.0592 80.407C54.1736 80.407 54.288 80.2551 54.4041 80.1782L66.0767 64.411C66.0767 64.3342 66.1536 64.2591 66.1911 64.1447L70.8594 45.1408C70.9363 44.837 70.7826 44.4938 70.4769 44.3401V44.3776ZM65.0061 29.2592H58.9974C58.0412 29.2217 57.2369 28.421 57.2745 27.4702C57.2745 26.5176 58.0394 25.7187 58.9974 25.7187H64.9668C65.9623 25.7187 66.7273 26.5176 66.7273 27.5077C66.7273 28.4979 65.9623 29.2217 65.0044 29.2592H65.0061Z"
                  fill="#010101"
                />
              </svg>
              <CertificationTitle htmlTitle={'Full Focus Planner <br /><br />'} />
              <CertificationCopy>
                A lot of people set goals but never see them through. Your Full Focus Planner Certified Coach will help
                you set clear goals, install regular reviews, build habits that stick, and keep you focused on what
                really matters. With their guidance, you’ll actually achieve the success you’ve been aiming for.
              </CertificationCopy>
              <div className="flex justify-start items-start mt-auto">
                <Link
                  to={{
                    pathname: 'search',
                    state: {
                      preSelectedFilters: {
                        type: 'certifications',
                        name: 'Full Focus',
                      },
                    },
                  }}
                  role="button"
                  className="largeBtn cta"
                  style={{
                    marginTop: 'auto',
                    display: 'block',
                    marginLeft: 'none !important',
                    marginRight: 'none !important',
                  }}
                >
                  Find Your Coach
                </Link>
              </div>
            </CertificationCard>
            <CertificationCard>
              <svg
                style={{
                  minHeight: '120px',
                  minWidth: '120px',
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="124"
                height="124"
                viewBox="0 0 124 124"
                fill="none"
              >
                <path
                  d="M122.002 62C122.002 95.1375 95.1393 122 62.0018 122C28.8643 122 2 95.1375 2 62C2 28.8625 28.8643 2 62.0018 2C95.1393 2 122.002 28.8625 122.002 62Z"
                  stroke="#010101"
                  stroke-width="3"
                  stroke-miterlimit="10"
                />
                <path
                  d="M67.6404 59.9996H52.4857C51.8343 59.9996 51.3013 60.526 51.3013 61.1841V67.9026C51.3013 68.9621 50.0247 69.4885 49.2745 68.7449L40.8845 60.3483C40.6608 60.1246 40.3581 59.9996 40.0422 59.9996H33.1854C32.534 59.9996 32.001 59.4732 32.001 58.8151V42.0351C32.001 41.3837 32.5274 40.8506 33.1854 40.8506H67.6404C68.2918 40.8506 68.8248 41.3771 68.8248 42.0351V58.8151C68.8248 59.4666 68.2984 59.9996 67.6404 59.9996ZM90.8297 64.0005H84.1111C83.7952 64.0005 83.4925 63.8754 83.2688 63.6517L74.8722 55.2551C74.1286 54.5115 72.8455 55.038 72.8455 56.0974V62.816C72.8455 63.474 72.3125 64.0005 71.661 64.0005H56.3616C55.7035 64.0005 55.1771 64.5335 55.1771 65.1849V81.9649C55.1771 82.623 55.7101 83.1494 56.3616 83.1494H90.8165C91.4745 83.1494 92.001 82.6164 92.001 81.9649V65.1849C92.001 64.5269 91.4745 64.0005 90.8231 64.0005H90.8297Z"
                  fill="black"
                />
              </svg>
              <CertificationTitle htmlTitle={'StoryBrand <br /><br />'} />
              <CertificationCopy>
                Confusing marketing is the fastest way to lose potential sales. The StoryBrand Framework helps
                businesses clarify their messaging so customers pay attention and take action. Your StoryBrand Certified
                Coach will guide you through this proven process to craft a clear message, create effective marketing
                assets, and develop a strategy that gets real results.
              </CertificationCopy>
              <div className="flex justify-start items-start mt-auto">
                <Link
                  to={{
                    pathname: 'search',
                    state: {
                      preSelectedFilters: {
                        type: 'certifications',
                        name: 'StoryBrand Coach',
                      },
                    },
                  }}
                  role="button"
                  className="largeBtn cta"
                  style={{
                    marginTop: 'auto',
                    display: 'block',
                    marginLeft: 'none !important',
                    marginRight: 'none !important',
                  }}
                >
                  Find Your Coach
                </Link>
              </div>
            </CertificationCard>
          </div>
        </SectionContainer>
        <section class="what-you-get">
          <div class="pw">
            <div data-st="st-seven" class="content">
              <h2 className="text-white">
                You need a <br class="desktop-only" />
                <span>business coach.</span>
              </h2>
              <p className="text-white">
                Most entrepreneurs are great in some parts of their business but need a lot of help with the rest. Our
                business coaches are certified to guide you through the essential pillars of running a business. Your
                coach will step in with proven frameworks and clear advice where you need it most, so you can focus on
                what you enjoy.
              </p>
              <a role="button" class="largeBtn cta" href="/search">
                Find Your Coach
              </a>
            </div>
            <div data-st="st-five" class="image-right">
              <img src={require(`../../../assets/mba-section-image.png`).default} alt="Teacher Teaching Class" />
            </div>
          </div>
        </section>
        <SectionContainer>
          <h2 className="md:text-7xl text-5xl font-extrabold text-center tracking-wide md:max-w-6xl md:px-0 px-4 md:mt-24">
            <span className="light-text-gradient">
              You don’t have to go through life burned out and stretched thin.
            </span>
            <span className="text-cbPink"> Our business coaches can help you…</span>
          </h2>

          <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
            <GridCard copy={'Get out of the day-to-day'} />
            <GridCard copy={'Put the right processes in place'} />
            <GridCard copy={'Get dependable, predictable results'} />
            <GridCard copy={'Achieve your most ambitious goals'} />
            <GridCard copy={'Break revenue records'} />
            <GridCard copy={'Enjoy more free time and flexibility'} />
          </div>
          <Link role="button" to="/search" className="largeBtn cta">
            FIND YOUR COACH
          </Link>
        </SectionContainer>
        <SectionContainer isDark>
          <div className="flex w-full">
            <div className="md:pl-4 md:px-0 px-4 flex flex-col space-y-12 md:w-9/12 w-full">
              <h3 className="text-gradient-lavender text-3xl max-w-3xl" style={{ fontWeight: 500 }}>
                Are you interested in building a successful coaching business of your own?
              </h3>
              <h2 className="text-left text-gradient-lavender md:text-7xl text-5xl font-extrabold tracking-wide xl:max-w-5xl md:max-w-3xl max-w-full">
                If you want to be unstoppable as a coach, you need 3 things:
              </h2>
              <ol className="text-white list-decimal pl-4">
                <li className="text-white">
                  A system to find and retain <strong>high-paying clients</strong>
                </li>
                <li className="text-white">
                  A <strong>proven curriculum</strong> that delivers results to clients
                </li>
                <li className="text-white">
                  A <strong>community of coaches</strong> you can learn from
                </li>
              </ol>
              <p className="text-white font-light text-2xl max-w-3xl tracking-wider" style={{ fontWeight: 500 }}>
                Get these and much more when you join{' '}
                <a
                  class="text-cbPink font-bold underline"
                  href={'https://www.coachbuilder.com'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Coach Builder
                </a>
                .
              </p>
              <a
                role="button"
                target="_blank"
                className="largeBtn no-margin"
                href="https://www.coachbuilder.com/"
                rel="noopener noreferrer"
              >
                SCHEDULE A CALL
              </a>
            </div>
            <img
              className="w-[450px] h-auto lg:block hidden"
              src={require(`../../../assets/biz-coach-side-image.png`).default}
              alt="person"
            />
          </div>
        </SectionContainer>
      </div>
    )
  }
}
export default HomeHAC
